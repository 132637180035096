@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700;900&display=swap");
* {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

button {
  border: none;
}
::placeholder {
  text-align: center;
}
