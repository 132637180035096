.header-logOut-btn {
  background-image: url("./Ellipse\ 11.png");
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-position: center;
  cursor: pointer;
}

.App {
  overflow: hidden;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  background: url("./Background.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.container {
  height: 513px;
  width: 367px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: auto;
  background-color: rgb(32, 32, 32);
  border-radius: 4%;
}
.nftImage {
  width: 538px;
  height: 538px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 50px;
  background-color: rgb(32, 32, 32);
}
.header-logo {
  height: 18px;
  padding-left: 25px;
}
.header-logOut {
  padding-right: 25px;
}

.hero {
  margin-bottom: 10px;
}

.item {
  padding: 10px;
}

.logIn-btn {
  height: 50px;
  width: 267px;
  border-radius: 99px;
  cursor: pointer;
}
.hero-p {
  color: white;
}

.marketplaces {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}

.searchPanel-container {
  background-color: rgb(32, 32, 32);
  height: 286px;
  width: 562px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: auto;
  border-radius: 4%;
}
.searchPanel-Icon {
  margin-right: 23px;
}

.searchbar {
  width: 380px;
  height: 36px;
  border-radius: 99px 0px 0px 99px;
  background-color: #121212;
  color: white;
  border: none;
  margin-left: 19px;
  padding-left: 30px;
}
.searchbar-btn {
  background: url("./SearchBTN.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 41px;
  width: 46px;
  border-radius: 0px 99px 99px 0px;
  cursor: pointer;
}

.searchbar-container {
  padding: 10px 10px 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2px;
}

.searchbar-p1 {
  color: white;
  width: 462px;
}

.searchbar-p2 {
  color: white;
  width: 462px;
  margin-top: -10px;
}

.card-container {
  width: 942px;
  height: 594px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: auto;

  margin-top: 60px;
}

.card-header {
  width: 936px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.5px solid black;
  background: rgba(107, 107, 107, 0.25);
  border-radius: 40px 40px 0px 0px;
}
.card-header > img {
  height: 23px;
  margin-left: 20px;
}
.card-searchbar {
  width: 308px;
  height: 36px;
  border-radius: 99px 0px 0px 99px;
  background-color: #121212;
  color: white;
  border: none;
}

.card-container-below {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-radius: 0px 0px 40px 40px;
  border: 0.5px solid black;
}
.card-container-below-left {
  flex: 1;
  width: 532px;
  height: 532px;
}
.card-container-below-right {
  flex: 1;
  width: 404px;
  height: 538px;
  background-color: rgb(32, 32, 32);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.nft-name-placeholder {
  color: #6b6b6b;
  align-self: flex-start;
  padding-left: 30px;
  padding-top: 20px;
}

.nft-name {
  color: white;
  background-color: rgb(24, 24, 27);
  border-radius: 20px;
  width: 354px;
  height: 38px;
  display: flex;
  margin-top: 17px;
}
.nft-name > p {
  justify-content: center;
  align-self: flex-end;
  padding-left: 8px;
  padding-bottom: 8px;
  font-size: 18px;
}

.contentDivider {
  width: 354px;
  margin-bottom: 5px;
}

.lastcontentDivider {
  width: 354px;
  margin-bottom: 10px;
}

.sign-card {
  width: 354px;
  height: 50px;
  background-color: rgb(0, 255, 110);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 20px;
}
.sign-card,
.sign-card-fake {
  font-weight: bold;
}

.sign-card-fake {
  width: 354px;
  height: 50px;
  background-color: rgb(255, 55, 62);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 20px;
}

.nft-address-placeholder {
  color: #6b6b6b;
  align-self: flex-start;
  padding-left: 30px;
  padding-top: 20px;
}
.nft-addresses {
  display: flex;
  width: 344px;
  height: 132px;
  background-color: rgb(24, 24, 27);
  border-radius: 20px;
  margin-top: 15px;
}

.nft-addresses-left {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 12px;
  margin-top: 5px;
  align-items: flex-start;
  padding-left: 10px;
  color: white;
}

.nft-addresses-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 12px;
  margin-top: 5px;
  align-items: flex-end;
  padding-right: 10px;
  color: gray;
}

.nft-addresses-right > a {
  text-decoration: none;
}
a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

.nft-addresses-right-in > a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.SolscanIcon {
  width: 15px;
  margin-right: 5px;
}

.att-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 354px;
  height: 112px;
}

.att-container-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.att-card {
  width: 111px;
  height: 51px;
  background-color: rgb(24, 24, 27);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin: 0px 5px 5px 5px;
  border-radius: 8px;
}

.att-card-head {
  color: #5e5e5e;
  margin-left: 5px;
  font-size: 12px;
}

.att-card-content {
  color: white;
  margin-left: 5px;
  text-align: left;
}

/* MOBILE    */

@media screen and (max-width: 480px) {
  .App {
    overflow: hidden;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    background: url("./Background.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .container {
    height: 513px;
    width: 367px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: auto;
    background-color: rgb(32, 32, 32);
    border-radius: 4%;
    margin-top: 170px;
  }
  .nftImage {
    width: 370px;
    height: 370px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: 50px;
    background-color: rgb(32, 32, 32);
  }
  .header-logo {
    height: 18px;
    padding-left: 25px;
  }
  .header-logOut {
    padding-right: 25px;
  }

  .hero {
    margin-bottom: 10px;
  }

  .item {
    padding: 10px;
  }

  .logIn-btn {
    height: 50px;
    width: 267px;
    border-radius: 99px;
    cursor: pointer;
  }
  .hero-p {
    color: white;
  }

  .marketplaces {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }

  .searchPanel-container {
    background-color: rgb(32, 32, 32);
    height: 286px;
    width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: auto;
    border-radius: 4%;
  }

  .searchbar {
    width: 270px;
    height: 36px;
    border-radius: 99px 0px 0px 99px;
    background-color: #121212;
    color: white;
  }
  .searchbar-btn {
    background: url("./SearchBTN.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 41px;
    width: 46px;
    border-radius: 0px 99px 99px 0px;
  }

  .searchbar-container {
    padding: 10px 10px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2px;
  }

  .searchbar-p1 {
    color: white;
    width: 270px;
  }

  .searchbar-p2 {
    color: white;
    width: 280px;
    margin-top: -10px;
  }
  .searchPanel-Icon {
    margin-left: 15px;
  }

  .card-container {
    width: 370px;
    height: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: auto;
    border-radius: 4%;
    margin-top: 60px;
  }

  .card-header {
    width: 370px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: rgba(107, 107, 107, 0.25);
  }
  .card-header > img {
    height: 23px;
    margin-left: 20px;
  }
  .card-searchbar {
    width: 145px;
    height: 36px;
    border-radius: 99px 0px 0px 99px;
    background-color: #121212;
    color: white;
    border: none;
  }

  .card-container-below {
    display: flex;
    flex-direction: column;
  }
  .card-container-below-left {
    flex: 1;
    width: 370px;
    height: 370px;
  }
  .card-container-below-right {
    flex: 1;
    width: 370px;
    height: 470px;
    background-color: rgb(32, 32, 32);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .nft-name-placeholder {
    color: #6b6b6b;
    align-self: flex-start;
    padding-left: 30px;
    padding-top: 20px;
  }

  .nft-name {
    color: white;
    background-color: rgb(24, 24, 27);
    border-radius: 20px;
    width: 354px;
    height: 38px;
    display: flex;
    margin-top: 17px;
  }
  .nft-name > p {
    justify-content: center;
    align-self: flex-end;
    padding-left: 8px;
    padding-bottom: 8px;
    font-size: 18px;
  }

  .contentDivider {
    width: 354px;
    margin-bottom: 5px;
  }

  .lastcontentDivider {
    width: 354px;
    margin-bottom: 10px;
  }

  .sign-card {
    width: 354px;
    height: 50px;
    background-color: rgb(0, 255, 110);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 20px;
  }
  .sign-card,
  .sign-card-fake {
    font-weight: bold;
  }

  .sign-card-fake {
    width: 354px;
    height: 50px;
    background-color: rgb(255, 55, 62);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 20px;
  }

  .nft-address-placeholder {
    color: #6b6b6b;
    align-self: flex-start;
    padding-left: 30px;
    padding-top: 20px;
  }
  .nft-addresses {
    display: flex;
    width: 344px;
    height: 132px;
    background-color: rgb(24, 24, 27);
    border-radius: 20px;
    margin-top: 15px;
  }

  .nft-addresses-left {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding-left: 10px;
    color: white;
  }

  .nft-addresses-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 12px;
    margin-top: 5px;
    align-items: flex-end;
    padding-right: 10px;
    color: gray;
  }

  .nft-addresses-right > a {
    text-decoration: none;
  }
  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }

  .att-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 354px;
    height: 112px;
  }

  .att-container-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .att-card {
    width: 111px;
    height: 51px;
    background-color: rgb(24, 24, 27);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin: 0px 5px 5px 5px;
    border-radius: 8px;
  }

  .att-card-head {
    color: #5e5e5e;
    margin-left: 5px;
    font-size: 12px;
  }

  .att-card-content {
    color: white;
    margin-left: 5px;
  }
}

/* IPAD VIEW */

@media (min-width: 481px) and (max-width: 950px) {
  .header-logOut-btn {
    background-image: url("./Ellipse\ 11.png");
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-position: center;
    cursor: pointer;
  }

  .App {
    overflow: scroll;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    background: url("./Background.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .container {
    height: 513px;
    width: 367px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: auto;
    background-color: rgb(32, 32, 32);
    border-radius: 4%;
  }
  .nftImage {
    width: 500px;
    height: 500px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: 50px;
    background-color: rgb(32, 32, 32);
  }
  .header-logo {
    height: 18px;
    padding-left: 25px;
  }
  .header-logOut {
    padding-right: 25px;
  }

  .hero {
    margin-bottom: 10px;
  }

  .item {
    padding: 10px;
  }

  .logIn-btn {
    height: 50px;
    width: 267px;
    border-radius: 99px;
    cursor: pointer;
  }
  .hero-p {
    color: white;
  }

  .marketplaces {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }

  .searchPanel-container {
    background-color: rgb(32, 32, 32);
    height: 286px;
    width: 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: auto;
    border-radius: 4%;
  }

  .searchbar {
    width: 355px;
    height: 36px;
    border-radius: 99px 0px 0px 99px;
    background-color: #121212;
    color: white;
    border: none;
  }
  .searchbar-btn {
    background: url("./SearchBTN.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 41px;
    width: 46px;
    border-radius: 0px 99px 99px 0px;
  }

  .searchbar-container {
    padding: 10px 10px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2px;
  }

  .searchbar-p1 {
    color: white;
    width: 400px;
  }

  .searchbar-p2 {
    color: white;
    width: 400px;
    margin-top: -10px;
  }

  .card-container {
    width: 500px;
    height: 1120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: auto;
    border-radius: 4%;
    margin-top: 300px;
  }

  .card-header {
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: rgba(107, 107, 107, 0.25);
  }
  .card-header > img {
    height: 23px;
    margin-left: 20px;
  }
  .card-searchbar {
    width: 225px;
    height: 36px;
    border-radius: 99px 0px 0px 99px;
    background-color: #121212;
    color: white;
    border: none;
  }

  .card-container-below {
    display: flex;
    flex-direction: column;
  }
  .card-container-below-left {
    flex: 1;
    width: 500px;
    height: 500px;
  }
  .card-container-below-right {
    flex: 1;
    width: 500px;
    height: 538px;
    background-color: rgb(32, 32, 32);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .nft-name-placeholder {
    color: #6b6b6b;
    align-self: flex-start;
    padding-left: 30px;
    padding-top: 20px;
  }

  .nft-name {
    color: white;
    background-color: rgb(24, 24, 27);
    border-radius: 20px;
    width: 440px;
    height: 38px;
    display: flex;
    margin-top: 17px;
  }
  .nft-name > p {
    justify-content: center;
    align-self: flex-end;
    padding-left: 8px;
    padding-bottom: 8px;
    font-size: 18px;
  }

  .contentDivider {
    width: 440px;
    margin-bottom: 5px;
  }

  .lastcontentDivider {
    width: 440px;
    margin-bottom: 10px;
  }

  .sign-card {
    width: 440px;
    height: 50px;
    background-color: rgb(0, 255, 110);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 20px;
  }
  .sign-card,
  .sign-card-fake {
    font-weight: bold;
  }

  .sign-card-fake {
    width: 440px;
    height: 50px;
    background-color: rgb(255, 55, 62);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 20px;
  }

  .nft-address-placeholder {
    color: #6b6b6b;
    align-self: flex-start;
    padding-left: 30px;
    padding-top: 20px;
  }
  .nft-addresses {
    display: flex;
    width: 430px;
    height: 132px;
    background-color: rgb(24, 24, 27);
    border-radius: 20px;
    margin-top: 15px;
  }

  .nft-addresses-left {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding-left: 10px;
    color: white;
  }

  .nft-addresses-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 12px;
    margin-top: 5px;
    align-items: flex-end;
    padding-right: 10px;
    color: gray;
  }

  .nft-addresses-right > a {
    text-decoration: none;
  }
  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }

  .att-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 430px;
    height: 112px;
  }

  .att-container-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .att-card {
    width: 140px;
    height: 60px;
    background-color: rgb(24, 24, 27);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin: 0px 5px 5px 5px;
    border-radius: 8px;
  }

  .att-card-head {
    color: #5e5e5e;
    margin-left: 5px;
    font-size: 14px;
    font-weight: 900;
  }

  .att-card-content {
    color: white;
    margin-left: 5px;
    display: flex;
  }
}

.searchbar-btn:active {
  -webkit-animation: wobble-ver-left 3s both;
  animation: wobble-ver-left 3s both;
}

@-webkit-keyframes wobble-ver-left {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(-6deg);
    transform: translateY(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(6deg);
    transform: translateY(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(-3.6deg);
    transform: translateY(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(2.4deg);
    transform: translateY(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(-1.2deg);
    transform: translateY(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-ver-left {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(-6deg);
    transform: translateY(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(6deg);
    transform: translateY(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(-3.6deg);
    transform: translateY(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(2.4deg);
    transform: translateY(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(-1.2deg);
    transform: translateY(-6px) rotate(-1.2deg);
  }
}
.sign-card-fake:hover {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}
.sign-card:hover {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@media screen and (min-height: 901px) {
  .App {
    height: 100vh;
  }
  .searchPanel-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 300px;
  }
}
